import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Menu from '../components/menu';
import Footer from '../components/footer';
import './reset.less';
import * as css from './index.module.less';

export const query = graphql
`
    query SITE_INDEX_QUERY {
        site {
            siteMetadata {
                title
                description
            }
        }
        allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true } } }
        ) {
            nodes {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                    title
                    date
                }
                fields {
                  slug
                }
            }
        }
    }
`;

// <div>
//     <h1>{data.site.siteMetadata.title}</h1>
//     <p>{data.site.siteMetadata.description}</p>
// </div>
const HomePage = ({ data }) => {
    return (
        <div>
            <Menu />

            <div className={css.postList}>
                {data.allMdx.nodes.map(({ frontmatter, fields }) => (
                    <div className={css.card} key={fields.slug}>
                        <Link to={fields.slug}>
                            <h1>{frontmatter.title}</h1>
                        </Link>
                    </div>
                ))}
            </div>
            <Footer />
      </div>
  )
};

export default HomePage;

// <script src="https://eqcn.ajz.miesnfu.com/wp-content/plugins/wp-3d-pony/live2dw/lib/L2Dwidget.min.js"></script>
// <script>
//     var jsonModel = [
//     "https://unpkg.com/live2d-widget-model-miku@1.0.5/assets/miku.model.json",// 初音
//     "https://unpkg.com/live2d-widget-model-shizuku@1.0.5/assets/shizuku.model.json",// 萌娘
//     "https://unpkg.com/live2d-widget-model-koharu@1.0.5/assets/koharu.model.json",// 小可爱（女）
//     "https://unpkg.com/live2d-widget-model-haruto@1.0.5/assets/haruto.model.json",// 小可爱（男）
//     "https://unpkg.com/live2d-widget-model-chitose@1.0.5/assets/chitose.model.json",// 小帅哥
//     ];
//     L2Dwidget.init({ 
//         "model": { 
//             "jsonPath": jsonModel[Math.floor(Math.random()*(jsonModel.length-1))],
//             "scale": 1 
//         }, 
//         "display": { 
//             "position": "left", // 位置left、right
//             "width": 66, // 宽度
//             "height": 90, // 高度
//             "hOffset": 10, // 横向边距
//             "vOffset": 0 // 众向边距
//         }, 
//         "mobile": { 
//             "show": false // 手机是否显示
//         },
//     });
// </script>